/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '~/components/Icon/types';

const IconHome: FC<IconType> = ({ color }) => (
  <svg height="100%" viewBox="0 0 576 512" width="100%">
    <g transform="matrix(1.35068,0,0,1.35068,-100.994,-154.471)">
      <path
        d="M280.37,128.27L96,280.12L96,464C96,472.777 103.223,480 112,480L224.06,479.71C232.807,479.666 239.98,472.457 239.98,463.71L239.98,368C239.98,359.223 247.203,352 255.98,352L319.98,352C328.757,352 335.98,359.223 335.98,368L335.98,463.64C335.98,463.657 335.98,463.673 335.98,463.69C335.98,472.467 343.203,479.69 351.98,479.69C351.98,479.69 351.98,479.69 351.98,479.69L464,480C472.777,480 480,472.777 480,464L480,280.01L295.67,128.27C291.221,124.684 284.819,124.684 280.37,128.27Z"
        fill={color || 'currentColor'}
      />
    </g>
  </svg>
);

export default IconHome;
